const ROUTES = {
  home: "/",
  lessons: "/lessons",
  writingLabs: "/writing-labs",
  students: "/students",
  reports: "/reports",
  settings: "/settings",
  auth: {
    signup: "/signup",
    passwordReset: "/password-reset"
  }
};

export const ROUTE_CAPTIONS = {
  [ROUTES.home]: "Welcome, {{user}}",
  [ROUTES.writingLabs]: "Paper Reviews",
  [ROUTES.lessons]: "Live Lessons",
  [ROUTES.students]: "Students",
  [ROUTES.reports]: "Reports",
  [ROUTES.settings]: "Settings",
  [ROUTES.auth.signup]: "Signup",
  [ROUTES.auth.passwordReset]: "Reset Password",
  "/lessons/[id]": "Lesson #{{id}} Details"
};

export default ROUTES;
