import CircularProgress from "@material-ui/core/CircularProgress";
import classnames from "classnames";
import * as React from "react";

import LoadingIndicatorCaption from "./LoadingIndicatorCaption";
import { useInViewportOffset } from "./useInViewportOffset";

interface Props {
  caption?: React.ReactNode;
  className?: string;
  indicatorInViewport?: boolean;
}

const LoadingIndicator: React.FC<Props> = ({
  className,
  caption,
  indicatorInViewport
}: Props): JSX.Element => {
  const loaderRef = useInViewportOffset(indicatorInViewport);

  return (
    <div
      ref={loaderRef}
      aria-busy="true"
      className={classnames(
        className,
        "flex items-center justify-center h-full w-full flex-col absolute left-0 top-0 bg-cream-100",
        indicatorInViewport ? "justify-[default]" : "justify-center"
      )}
    >
      <LoadingIndicatorCaption caption={caption} />
      <CircularProgress className="mt-[15px] text-cream-300" color="primary" />
    </div>
  );
};

export default LoadingIndicator;
