import { Button, Link } from "@material-ui/core";
import { default as NextLink } from "next/link";
import * as React from "react";

interface Props {
  mainAction: {
    caption: string;
    disabled: boolean;
  };
  secondaryAction?: {
    caption: string;
    href: string;
  };
}

const AuthFormActions: React.FC<Props> = ({
  mainAction,
  secondaryAction
}: Props): JSX.Element => (
  <footer className="flex items-center justify-between">
    <Button
      className="min-w-[165px] text-white"
      variant="contained"
      color="primary"
      type="submit"
      disabled={mainAction.disabled}
    >
      {mainAction.caption}
    </Button>

    {secondaryAction && (
      <NextLink href={secondaryAction.href}>
        <Link className="explanatoryTextLink">{secondaryAction.caption}</Link>
      </NextLink>
    )}
  </footer>
);

export default AuthFormActions;
